import { AxiosInstance } from "axios";

import { AtlasCohortDefinition, AtlasListing } from "../@types/atlas";
import { ConceptSetItem } from "../@types/service";

export const fetchAtlasCohortDefinitions = async (
  client: AxiosInstance,
): Promise<AtlasListing[]> => {
  return client
    .get<AtlasListing[]>("/cohortdefinition")
    .then((resp) => {
      return resp.data?.map((d) => {
        d.name = d.name + "  |  " + d.id;
        return d;
      });
    })
    .catch((err) => {
      throw err;
    });
};

export const fetchAtlasCohortDefinition = async (
  id: number,
  client: AxiosInstance,
): Promise<AtlasCohortDefinition> => {
  return client
    .get<AtlasCohortDefinition>(`/cohortdefinition/${id}`)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const fetchAtlasConceptSets = async (
  client: AxiosInstance,
): Promise<AtlasListing[] | Error> => {
  return client
    .get<AtlasListing[]>("/conceptset")
    .then((resp) => {
      return resp.data?.map((d) => {
        d.name = d.name + "  |  " + d.id;
        return d;
      });
    })
    .catch((err) => {
      return err;
    });
};

export const fetchAtlasConceptSet = async (
  id: number,
  client: AxiosInstance,
): Promise<{ items: ConceptSetItem[] }> => {
  return client
    .get<{ items: ConceptSetItem[] }>(`/conceptset/${id}/expression`)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};
