import { AxiosInstance } from "axios";

import { Tag } from "../@types/service";

const base = "/tags";

export const fetchTags = async (
  client: AxiosInstance,
): Promise<Tag[]> => {
  return client
    .get<Tag[]>(`${base}`)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const fetchTagsForConceptSet = async (
  client: AxiosInstance,
  id: number
): Promise<Tag[]> => {
  return client
    .get<Tag[]>(`${base}/${id}/items`)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const deleteTag = async (
  id: number,
  client: AxiosInstance,
) => {
  return client
    .delete<void>(`${base}/${id}`)
    .then(() => {})
    .catch((err) => {
      throw err;
    });
};

export const saveTag = async (
  body: Tag,
  client: AxiosInstance,
) => {
  return client
    .post<void>(`${base}/save`, body)
    .then(() => {})
    .catch((err) => {
      throw err;
    });
};

export const saveTagsForConceptSet = async (
  body: Tag[],
  id: number,
  client: AxiosInstance,
): Promise<Tag[]> => {
  return client
    .post<Tag[]>(`${base}/${id}/items`, body)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};
